@import "variables";
@import "functions";

html {
  scroll-behavior: smooth;
}

body {
  color: $text-color;
  font-size: $font-size-small;
  font-weight: 200;
  font-family: $font-family-body;
  line-height: $line-height-base;
  background-color: $bg-body;
}

a:focus,
.focus a {
  outline: none !important;
}

.bg-brown {
  background-color: rgba($bg-brown, 0.5);
}

.bg-gray {
  background-color: rgba($bg-gray, 1);
}

.bg-main {
  background: url("../images/bg_main.png") transparent no-repeat;
}
.bg-pos-first {
  background-position: 50% 10%;
}
.bg-pos-top {
  background-position: 50% 20%;
}
.bg-pos-center {
  background-position: 50% 50%;
}
.bg-pos-bottom {
  background-position: 50% 80%;
}

a {
  color: $color-primary;
  outline: none;
  text-decoration: none;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}
a:hover {
  color: $color-primary;
  outline: none;
  text-decoration: none;
}

.small {
  font-size: 14px;
}


h1, h2, h3, h4, h5, h6 {
  color: $text-title;
  font-family: $font-family-heading;
  font-weight: 400;
}
h1 {
  font-size: 1.65rem;
  line-height: 1.65;
  margin-bottom: 1rem;
}
h2 {
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: .8rem;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.6;
}
h4 {
  font-size: 1.1rem;
  line-height: 1.4;
}
h5 {
  font-size: 1rem;
  line-height: 1.2;
}
h6 {
  font-size: .8rem;
  line-height: 1.1;
}

h1.color-primary ,
h2.color-primary ,
h3.color-primary {
  color: $color-primary;
}

.color-title {
  color: #595959;
}

.display-1, .display-2, .display-3, .display-4 {
  color: $color-primary;
  font-family: $font-family-heading;
}

p a:hover {
  color: #515151;
}

ul {
  list-style-position: outside;
  padding-left: 23px;
}

.block-content ul {
  list-style-position: outside;
  padding-left: 23px;
  margin-left: 0;
}

.lrl-icon {
  display: block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;

  &.lrl-user {
    background-image: url("../icons/account-outline.svg");
  }

  &.lrl-cart {
    background-image: url("../icons/cart-outline.svg");
  }

  &.lrl-search {
    background-image: url("../icons/magnify.svg");
  }
}

.btn {
  color: $color-white;
  font-family: $font-family-heading;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

.btn.btn-normal {
  text-transform: inherit;
}

.btn-radius {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.btn-lg {
  font-size: 1rem;
  border: 2px solid;
}
.btn-md {
  font-size: .9rem;
  border: 2px solid;
}
.btn-sm {
  font-size: .8rem;
  border: 1px solid;
}
.btn-xs {
  font-size: .7rem;
  border: 1px solid;
}
.btn-primary {
  border-color: $btn-primary;
  background-color: $btn-primary;
}
button.button {
  font-family: $font-family-heading !important;
  font-weight: normal;
  font-size: 18px;
  text-transform: uppercase;
  border: 2px solid;
  padding: 5px 20px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #656464;
  border-color: $btn-cart;
  background-color: $btn-cart;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  cursor:pointer;
}

.btn-outline-primary {
  color: $btn-primary;
  border-color: $btn-primary;
  background-color: transparent;
}
.btn-outline-primary-invert {
  color: $color-white;
  border-color: $color-white;
  background-color: transparent;
}
.btn-secondary {
  border-color: $btn-secondary;
  background-color: $btn-secondary;
}
.btn-outline-secondary {
  color: $btn-secondary;
  border-color: $btn-secondary;
  background-color: transparent;
}
.btn-cart {
  color: #656464;
  border-color: $btn-cart;
  background-color: $btn-cart;
}
.btn-cart:hover, .btn-cart:focus, .btn-cart:active, .btn-cart.active, .open>.dropdown-toggle.btn-cart {
  color: $color-white;
  border-color: $btn-primary;
  background-color: $btn-primary;
}
.btn-cart-mobile{
  color: #656464;
  border-color: $btn-cart;
  background-color: $btn-cart;
}
.btn-cart-mobile:hover, .btn-cart-mobile:focus, .btn-cart-mobile:active, .btn-cart-mobile.active, .open>.dropdown-toggle.btn-cart-mobile {
  color: $color-white;
  border-color: $btn-primary;
  background-color: $btn-primary;
}
.btn-cart-mobile .btn-icon-cart {
  vertical-align: top;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: $color-white;
  border-color: rgba($btn-primary, 0.7);
  background-color: rgba($btn-primary, 0.7);
}
button.button:hover, button.button:focus {
  color: $color-white;
  border-color: $btn-primary;
  background-color: $btn-primary;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
  color: $color-white;
  border-color: rgba($btn-secondary, 0.7);
  background-color: rgba($btn-secondary, 0.7);
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-outline-primary {
  color: $color-white;
  border-color: rgba($btn-primary, 1);
  background-color: rgba($btn-primary, 1);
}
.btn-outline-primary-invert:hover, .btn-outline-primary-invert:focus, .btn-outline-primary-invert:active, .btn-outline-primary-invert.active, .open>.dropdown-toggle.btn-outline-primary-invert {
  color: $btn-primary !important;
  border-color: rgba($btn-primary, 1);
  background-color: rgba($color-white, 1);
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .open>.dropdown-toggle.btn-outline-secondary {
  color: $color-white;
  border-color: rgba($btn-secondary, 1);
  background-color: rgba($btn-secondary, 1);
}

.btn-left {
  float: left;
}
.btn-right {
  float: right;
}
.btn-center {
  margin-left: auto;
  margin-right: auto;
}

.border-primary {
  border-color: $color-primary !important;
}
.pt-6 {
  padding-top: 60px;
}
.pt-7 {
  padding-top: 70px;
}
.pt-8 {
  padding-top: 80px;
}
.pt-9 {
  padding-top: 90px;
}
.pt-10 {
  padding-top: 100px;
}
.pt-11 {
  padding-top: 110px;
}
.pt-12 {
  padding-top: 120px;
}
.pt-13 {
  padding-top: 130px;
}
.pt-14 {
  padding-top: 140px;
}
.pt-15 {
  padding-top: 150px;
}
.pb-6 {
  padding-bottom: 60px;
}
.pb-7 {
  padding-bottom: 70px;
}
.pb-8 {
  padding-bottom: 80px;
}
.pb-9 {
  padding-bottom: 90px;
}
.pb-10 {
  padding-bottom: 100px;
}
.pb-11 {
  padding-bottom: 110px;
}
.pb-12 {
  padding-bottom: 120px;
}
.pb-13 {
  padding-bottom: 130px;
}
.pb-14 {
  padding-bottom: 140px;
}
.pb-15 {
  padding-bottom: 150px;
}
.py-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.py-7 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.py-8 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.py-9 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.py-10 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.py-11 {
  padding-top: 110px;
  padding-bottom: 110px;
}
.py-12 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.py-13 {
  padding-top: 130px;
  padding-bottom: 130px;
}
.py-14 {
  padding-top: 140px;
  padding-bottom: 140px;
}
.py-15 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

.color-black { color: $color-black; }
.color-black-1 { color: $color-black; }
.color-black-2 { color: $color-black-2; }
.color-black-3 { color: $color-black-3; }
.color-white { color: $color-white; }
.color-primary { color: $color-primary; }
.color-secondary { color: $color-secondary; }

.container-img-fluid img {
  width: 100%;
  height: auto;
}

.img-responsive img,
.responsive-img img {
  width: 100%;
  height: auto;
}

.img-responsive.img-100 ,
.responsive-img.img-100 {
  width: 100%;
}

img {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.top-nav {
  padding: .75rem 0;
}
.top-nav-left,
.top-nav-right {
  margin: 0;
}
.top-nav-border {
  border-bottom: 2px solid #d5d5d5;
}
.top-nav li {
  font-size: 1em;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #9c9c9c;
}
.top-nav li a {
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}

.top-nav li a:hover {
  color: #515151;
}

.navbar {
  padding-top: .5rem;
  padding-bottom: 3rem;
}

.go-to-top {
  display: block;
}
.go-to-top:hover {
  opacity: 0.7;
}

.list-unstyled ,
.list-inline {
  margin-left: 0;
}

.footer-container {
  mix-blend-mode: multiply;
  background-color: $bg-gray;
}

.logo-footer {
  width: 120px;
  display: block;
  margin-bottom: 0.5em;
}

.list-footer-nav li {
  color: #7b7b7b;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1rem;
}

.list-footer-nav li:first-child {
  border-left: none;
}

.list-footer-nav li a {
  color: #7b7b7b;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}

.list-footer-nav li a:hover {
  color: #515151;
}

.link-social-media a {
  color: $color-primary !important;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}
.link-social-media a:hover {
  color: #515151 !important;
}

.footer-info p {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.footer-info a {
  color: #7b7b7b;
}
.footer-info a:hover {
  color: #515151;
}
.logo-dbd {
  width: 130px;
}

#map {
  width: 100%;
  height: 478px;
}

.google-map {
  height: 478px;
  overflow: hidden;
}

.contact-form label {
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #7b7b7b;
}
.contact-form label.error {
  color: $color-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}

.contact-form .form-control {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;

}
.contact-form .form-control:focus {
  outline-color: $color-primary !important;
}

.contact-form ::placeholder {
  color: #7b7b7b;
  opacity: 1;
}
.contact-form :-ms-input-placeholder {
  color: #7b7b7b;
}
.contact-form ::-ms-input-placeholder {
  color: #7b7b7b;
}

.form-review label {
  color: #878686;
}

.form-review .form-control {
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background-color: rgba(#eaeaea, 0.5);
}
.form-review .form-control:focus {
  outline-color: $color-primary;
}
.form-review .form-group {
  margin-bottom: 40px;
}
.form-review label.error {
  color: $color-primary;
  padding-top: 10px;
}

.list-category-sidebar li {
  color: #4e4e4e;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 1px;
}

.list-category-sidebar li li {
  color: #6e6f6e;
  font-size: 1.2rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.73px;
  font-family: $font-family-heading;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-category-sidebar li a {
  color: #4e4e4e;
}
.list-category-sidebar li ul li a {
  color: #6e6f6e;
}
.list-category-sidebar li ul li a:hover {
  color: $color-primary !important;
}
.list-category-sidebar li ul li.active a ,
.list-category-sidebar li ul li.current-menu-item a {
  color: $color-primary !important;
}
.list-category-sidebar li ul {
  list-style: none inside;
  padding-bottom: 20px;
  padding-left: 0;
  margin-left: 0;
}

.list-category-sidebar li:last-child ul {
  border-bottom: none;
}

.featured-title {
  object-fit: contain;
}
.featured-title h1, .featured-title h2 , .featured-title h3 {
  color: #baa88d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 22px;
  padding: .7em .9em .3em 0;
  display: block;
  margin-bottom: 0;
}

.category-title {
  object-fit: contain;
}
.category-title h1, .category-title h2, .category-title h3 {
  color: #4e4e4e;
  font-size: 1.25rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: 1px;
}
.category-title p {
  margin-top: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #9e9e9e;
}

.card-product {
  border: none;
}

.card-product .product-name {
  color: #3c3c3c;
  font-size: 22px;
  line-height: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}
.card-product .product-name:hover {
  color: $color-primary;
}
.product-desc {
  color: #656464;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
}
.product-price {
  color: #656464;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.45;
  letter-spacing: normal;
  font-weight: 500;
}

#coupon_code {
  padding-bottom: .3em;
}

.carousel-slider .carousel-control-prev ,
.carousel-slider .carousel-control-next {
  background-color: transparent;
  opacity: 0;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
}
.carousel-slider:hover {
  .carousel-control-prev ,
  .carousel-control-next {
    opacity: 1;
  }
}

.card-product-item {
  height: 660px;
}
.btn-add-to-cart {
  width: 200px;
}
.add-cart-qty {
  font-weight: 800;
  padding: 19px 15px;
  text-align: center;
  border: 2px solid $btn-cart;
  background-color: $btn-cart;
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
  border-left: 2px solid #656464 !important;
  border-right: 2px solid #656464 !important;
}
.comment {
  color: #656464;
  object-fit: contain;
  mix-blend-mode: multiply;
  background-color: rgba(#d7cab4, 0.5);
  margin-bottom: 30px;
}
.comment-info {
  margin-bottom: 30px;
}

.btn-add-review {
  width: 200px;
}
.column-highlight {
  background-color: rgba(#d7cab4, 0.5);
}
.content-section {
  padding: 50px 0;
  background-color: rgba(#e4e4e4, 0.8);
}

.content-section h1, .content-section h2 , .content-section h3 , .content-section p, .content-section ul {
  color: #828282;
}
.content-section img {
  margin-bottom: 30px;
}
.section-title h1 {
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: #c2b198;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.section-title h1:after {
  content: "";
  height: 1px;
  margin-top: .5em;
  margin-left: 1em;
  flex: 1 1;
  border-bottom: 1px solid #d5d5d5;
  border-top: 1px solid #d5d5d5;
  background-color: #d5d5d5;
}

.woocommerce-message {
  border-top-color: $color-primary !important;
}
.woocommerce-info {
  border-top-color: $color-primary !important;
}
.woocommerce-error {
  border-top-color: $color-primary !important;
}
.woocommerce-error::before {
  content: '\e033';
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  color: $color-white;
  border-color: $btn-primary;
  background-color: $btn-primary;
  text-transform: uppercase;
  font-size: 20px;
  font-family: $font-family-heading;
  font-weight: normal;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
}

form.cart {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
}

.woocommerce-Price-amount.amount {
  color: #656464 !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.45 !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
}
.woocommerce .quantity {
  display: inline-block;
  margin-right: 15px;
  border: none !important;
  background-color: $btn-cart !important;
}

.woocommerce input[type=number]::-webkit-inner-spin-button,
.woocommerce input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.screen-reader-text {
  display:  none !important;
}
.woocommerce .quantity .qty {
  color: #656464 !important;
  width: 70px !important;
  line-height: 36px;
  vertical-align: text-top;
  display: inline-block;
  border: none !important;
  background-color: $btn-cart;
  border: none !important;
}
.woocommerce .quantity .qty::before ,
.woocommerce .quantity .qty::after {
  width: 0 !important;
  display: none !important;
}

.woocommerce .quantity .minus, .woocommerce .quantity .plus {
  color: #656464 !important;
  font-size: 38px !important;
  font-weight: 400 !important;
  vertical-align: top !important;
  text-align: center !important;
  border:none!important;
  cursor: pointer;
  padding-top: 0;
  width: 60px !important;
  display: inline-block;
  background-color: $btn-cart !important;
  height: 38px !important;
}
.woocommerce .quantity .minus {
  margin-right: 0 !important;
  border-right: 1px solid #656464 !important;
}
.woocommerce .quantity .plus {
  border-left: 1px solid #656464 !important;
}
.woocommerce .quantity .minus:hover ,
.woocommerce .quantity .plus:hover {
  color: $color-white !important;
  border-color: $btn-primary !important;
  background-color: $btn-primary !important;
}
button.single_add_to_cart_button {
  font-size: 19px !important;
  padding: 10px 30px !important;
  color: #656464 !important;
  border-color: $btn-cart !important;
  background-color: $btn-cart !important;
  display: inline-block !important;
}

.single_add_to_cart_button:hover, .single_add_to_cart_button:focus, .single_add_to_cart_button:active {
  color: $color-white !important;
  border-color: $btn-primary !important;
  background-color: $btn-primary !important;
}

table.shop_table_responsive tbody tr td,
table.shop_table_responsive tbody tr th {
  vertical-align: middle;
  padding: 0 .5rem;
}

.woocommerce-orders-table__cell-order-actions {
  .woocommerce-button {
    margin: .5rem;
    padding: 0 .5rem;
  }
}

.shop_table {
  .head,
  .cart-subtotal .product-name,
  .order-total .product-name {
    font-weight: bold;
  }
  .cart-subtotal,
  .order-total,
  .body .cart_item {
    display: flex;
    justify-content: space-between;
    margin: .25rem 0;
  }
  .woocommerce-shipping-totals {
    display: block;
    margin: .5rem 0;
  }
  .order-total {
    margin-top: 1rem;
  }
}

.woocommerce-order-details {
  margin-bottom: 2rem;
}
