:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

// Colors
$theme-color: #baa88d;
$color-primary: #baa88d;
$color-secondary: #515151;

$color-white: #ffffff;
$color-red: #ff0000;
$color-gray: #eeeeee;
$color-gray-1: #eeeeee;
$color-gray-2: #dddddd;
$color-gray-3: #cccccc;

$color-black: #000000;
$color-black-1: #333333;
$color-black-2: #666666;
$color-black-3: #999999;

$color-success: #00C851;
$color-success-dark: #007E33;
$color-danger: #ff4444;
$color-danger-dark: #CC0000;
$color-warming: #ffbb33;
$color-warming-dark: #FF8800;
$color-info: #33b5e5;
$color-info-dark: #0099CC;
$color-blue: var(--blue);
$color-indigo: var(--indigo);
$color-purple: var(--purple);
$color-red: var(--red);
$color-orange: var(--orange);
$color-yellow: var(--yellow);
$color-teal: var(--teal);
$color-cyan: var(--cyan);
$color-pink: var(--pink);
$color-dark-gray: #666666;


// Body
//$body-color: #000000;
//$body-bg: #ffffff;
//$bg-white: #ffffff;
//$bg-black: #000000;
//$bg-primary: $color-primary;
//$bg-secondary: $color-secondary;
//$bg-gray: #f4f4f4;



// Typography
$font-family-heading: 'Prompt', sans-serif;
$font-family-body: 'Prompt', sans-serif;
$font-family-nav: 'Prompt', sans-serif;
$font-family-icon: "FontAwesome";
$font-size-base: 16px;
$line-height-base: 25px;
$font-size-small: 14px;

// Elements
//$link-color: $primary-color;
//$link-hover-color: $primary-color;
//$text-color: $color-black;

//#ffffff
//#d8d8d8
//#f0f0f0
//#9c9c9c
//#575757
//#7b7b7b
//#515151
//#cab89a
//#757575
//#646464
//#baa88d
//#403d3d
//#d7cab4
//#bfbfbf

$bg-body: #ffffff;
$bg-brown: #d7cab4;
$bg-gray: #e4e4e4;
$bg-footer: #f0f0f0;

$text-title: #3c3c3c;
$text-color: #515151;

$btn-primary: #baa88d;
$btn-secondary: #8c8c8c;
$btn-cart: #e7e7e7;